import React from 'react';
import Logo from '../imgs/logo.png';

function AcercaDe(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 mt-4">
                    <div className="card">
                        <div className="card-header">
                            <h2 className="text-center">Acerca De</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 mt-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={Logo} alt="logo" style={{
                                        width: "200px",
                                        height: "200px",
                                        display: "block",
                                        margin: "auto"
                                    }} />
                                </div>
                                <div className="col-md-6">
                                    <h3>Hecho por Esteban Orellana</h3>
                                    <h5>Contacto:  <a href="mailto:info@valordivisas.cl">info@valordivisas.cl</a></h5>
                                    <h5>Todos los derechos reservados - {new Date().getFullYear()}</h5>
                                    <br />
                                    <h5>Agradecimientos</h5>
                                    <p>
                                        [API] Mi indicador: <a href="https://mindicador.cl/">https://mindicador.cl/</a>
                                        <br />
                                        [Publicador] Global3 : <a href="https://www.global3.cl/">https://www.global3.cl/</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default AcercaDe;