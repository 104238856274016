import React from 'react';

function Contacto() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-4">
                        <div className="card-header">
                            <h3>Políticas de Privacidad</h3>
                        </div>
                        <div className="card-body text-justify">
                        Mediante el uso de la aplicación acepta el la utilización de la información que la aplicación recopila, en los términos y condiciones establecidos en estás Políticas de Privacidad.
                        <br/><br/>
                        VALOR DIVISAS se reserva el derecho a modificar las presentes políticas de privacidad, y cualquier otro acuerdo que de ellas o del uso de la aplicación deriven, a su única y absoluta discreción por lo cual le recomendamos revisarlas periódicamente con el objeto de estar al tanto de eventuales cambios. La utilización del sistema con posterioridad a la publicación, constituye su aceptación a la modificación.
                        <br/><br/>
                        La presente Política de Privacidad constituye un acuerdo total entre las partes, no existiendo pactos orales, escritos u otro entendimiento o representación salvo lo expresamente establecido en este documento.
                        <br/><br/>
                        Información personal de identificación:
                        VALOR DIVISAS recolecta esta información cuando una persona entra a la aplicación y se registra como usuario y en conjunto con otras actividades, servicios u otros recursos queda activo en nuestra plataforma.
                        <br/><br/>
                        VALOR DIVISAS utiliza información personal proporcionadas por sus usuarios para que a su vez ésta pueda ser proporcionada a instituciones gubernamentales o privadas, incluyendo municipalidades, y empresas de estudios. Esta información personal puede incluir, pero no se limita a, números de teléfono, nombres, emails, etc. VALOR DIVISAS no compartirá esta información personal de identificación a terceras partes y tampoco la usará para otros propósitos que los señalados en esta política o los proporcionados por este servicio. Los datos personales se trabajan de forma encriptada.
                        <br/><br/>
                        Información no personal de identificación:
                        VALOR DIVISAS podrá recolectar información no personal de sus usuarios cuando ellos interactúen con la aplicación. Esta información podrá incluir el tipo de dispositivo e información técnica sobre los usuarios de los medios de conexión a la aplicación, como el sistema operativo y los proveedores de servicios de internet utilizados e información similar.
                        <br/><br/>
                        Cómo utilizamos la información recopilada:
                        VALOR DIVISAS recolecta y utiliza la información recopilada para los siguientes propósitos:
                        <br/><br/>
                        Información personal de identificación: Es utilizada con el sólo propósito de identificar y procesar las denuncias realizadas.
                        Información no personal de identificación: Esta información contribuye a mejorar la aplicación. Se pueden utilizar direcciones de correo electrónico para mandar a los usuarios información y actualizaciones, responder inquietudes, preguntas y otro tipo de solicitudes.
                        <br/><br/>
                        Cómo protegemos tu información:
                        VALOR DIVISAS adopta prácticas adecuadas de recopilación, almacenamiento y procesamiento de datos y medidas de seguridad para proteger contra el acceso no autorizado, alteración, divulgación o destrucción de información personal, nombre de usuario, contraseña y datos almacenados en la aplicación móvil. Los datos se trabajan de forma encriptada.
                        <br/><br/>
                        Cómo compartir su información personal:
                        VALOR DIVISAS no vende, transfiere o alquila información personal de identificación a otras personas. Es posible que comparta información genérica demográfica agregada y no vinculada a ninguna información de identificación personal con respecto a usuarios con socios de negocios, afiliados y anunciantes para los fines descritos anteriormente.
                        VALOR DIVISAS puede utilizar proveedores de servicios que ayuden a operar sus negocios, la aplicación móvil y/o administrar la aplicación móvil o actividades a su nombre (envío de boletines o encuestas). Así también podrá compartir su información con estos terceros para los fines antes descritos y limitados siempre a que el usuario haya otorgado su permiso.
                        <br/><br/>
                        Acceso a la información personal almacenada por VALOR DIVISAS:
                        Los usuarios tienen acceso a su información personal, que VALOR DIVISAS almacena en una base de datos segura, así como también tienen derecho a revisarla, actualizarla, editarla, o eliminarla si así lo quisieren.
                        <br/><br/>
                        Propiedad de datos:
                        Todos los datos e información personal son propiedad de los usuarios, por lo que pueden solicitar la transferencia de estos datos si no siguen contratando los servicios de VALOR DIVISAS.
                        <br/><br/>
                        Niños menores de 13 años:
                        Los sitios y aplicaciones de VALOR DIVISAS no están destinados a niños menores de 13 años de edad. No se recolecta a sabiendas información personal de identificación de niños menores de 13 años y en el caso de percatarse sobre la recolección de este tipo de información respecto de menores, se tomarán las medidas necesarias para eliminarla tan pronto como sea posible.
                        <br/><br/>
                        Cambios en esta política de privacidad:
                        VALOR DIVISAS puede cambiar o actualizar en cualquier momento esta política de privacidad. Cuando lo haga, se actualizará la fecha señalada al final de esta página. VALOR DIVISAS invita a sus usuarios a revisar frecuentemente esta página para enterarse de cualquier cambio y mantenerse informados de cómo se está ayudando a proteger la información personal que se recopila. El Usuario reconoce y acepta que es de su responsabilidad revisar estas políticas de privacidad periódicamente para tomar conocimiento de las modificaciones que pueda sufrir.
                        <br/><br/>
                        Su aceptación de estos términos:
                        Al utilizar la aplicación móvil de VALOR DIVISAS, el usuario está aceptando estas políticas y en caso de no estar de acuerdo puede no utilizarlo.
                        El uso continuo de la aplicación móvil después de la publicación de cambios en estas políticas se considerará aceptación respecto a dichos cambios.
                        <br/><br/>
                        Contáctenos:
                        Cualquier duda o consulta sobre estas políticas de privacidad, las prácticas de esta aplicación móvil, el usuario puede ponerse en contacto con VALOR DIVISAS a través de los siguientes datos de contacto:
                        <br/><br/>
                        Email: info@valordivisas.cl
                        <br/><br/>
                        Fecha actualización: 05 de Diciembre de 2020
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacto;