import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../imgs/logo.png';

function Navegacion(props) {
    return (
        <div className="container mt-4">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">
                    <img src={Logo} width="30" height="30" className="d-inline-block align-top pr-2" alt="" />
                    {props.titulo}
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <Link className="nav-link" to={"/"}>Inicio</Link>
                        </li>
                        <li className="nav-item dropdown active">
                            <a className=" nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Monedas
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <Link className="dropdown-item" to="/detallemoneda/uf">UF</Link>
                                <Link className="dropdown-item" to="/detallemoneda/ivp">IVP</Link>
                                <Link className="dropdown-item" to="/detallemoneda/dolar">Dolar obs.</Link>
                                <Link className="dropdown-item" to="/detallemoneda/dolar_intercambio">Dolar acu.</Link>
                                <Link className="dropdown-item" to="/detallemoneda/euro">Euro</Link>
                                <Link className="dropdown-item" to="/detallemoneda/ipc">IPC</Link>
                                <Link className="dropdown-item" to="/detallemoneda/utm">UTM</Link>
                                <Link className="dropdown-item" to="/detallemoneda/imacec">IMACEC</Link>
                                <Link className="dropdown-item" to="/detallemoneda/tpm">TPM</Link>
                                <Link className="dropdown-item" to="/detallemoneda/libra_cobre">Libra Cobre</Link>
                                <Link className="dropdown-item" to="/detallemoneda/tasa_desempleo">Tasa de Desempleo</Link>
                                <Link className="dropdown-item" to="/detallemoneda/bitcoin">Bitcoin</Link>
                            </div>
                        </li>
                        <li className="nav-item active">
                            <Link className="nav-link" to={"/AcercaDe"}>Acerca De</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div >
    );
}

export default Navegacion;