import React, { Component } from 'react';
import CartaValores from '../components/CartaValores';

class DetalleMoneda extends Component {
    constructor(props) {
        super();
        this.state = {
            nombre: props.match.params.moneda,
            valores: [],
            unidad_medida: "",
            nombre_completo: ""
        }
    }

    componentDidMount() {
        fetch('https://mindicador.cl/api/' + this.state.nombre)
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ valores: responseJson.serie });
                this.setState({ unidad_medida: responseJson.unidad_medida });
                this.setState({ nombre_completo: responseJson.nombre });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.nombre !== this.props.match.params.moneda) {
            fetch('https://mindicador.cl/api/' + this.props.match.params.moneda)
                .then(response => {
                    return response.json()
                })
                .then(responseJson => {
                    this.setState({ nombre: this.props.match.params.moneda });
                    this.setState({ valores: responseJson.serie });
                    this.setState({ unidad_medida: responseJson.unidad_medida });
                    this.setState({ nombre_completo: responseJson.nombre });
                });
        }

    }

    render() {
        let nombre_completo = this.state.nombre_completo;
        let unidad_medidaa = this.state.unidad_medida;

        const mostrarValores = this.state.valores.map(function (valor, i) {
            let moneda = {
                nombre: nombre_completo,
                valor: valor.valor,
                unidad_medida: unidad_medidaa,
                fecha: valor.fecha
            }

            return (
                <CartaValores moneda={moneda} />
            )
        });


        return (
            <div className="container" >
                <div className="row">
                    <div className="col-lg-12 mt-4">
                        <div className="card">
                            <div className="card-header">
                                <h2 className="text-center">{nombre_completo}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {mostrarValores}
                </div>
            </div>
        );
    }
}

export default DetalleMoneda;