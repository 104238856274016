import React, { Component } from 'react';
import CartaValores from '../components/CartaValores';

class Home extends Component {
    constructor() {
        super();
        this.state = {
            uf: [],
            ivp: [],
            dolar: [],
            dolar_intercambio: [],
            euro: [],
            ipc: [],
            utm: [],
            imacec: [],
            tpm: [],
            libra_cobre: [],
            tasa_desempleo: [],
            bitcoin: []
        }
    }

    componentDidMount() {
        fetch('https://mindicador.cl/api')
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ uf: responseJson.uf });
                this.setState({ ivp: responseJson.ivp });
                this.setState({ dolar: responseJson.dolar });
                this.setState({ dolar_intercambio: responseJson.dolar_intercambio });
                this.setState({ euro: responseJson.euro });
                this.setState({ ipc: responseJson.ipc });
                this.setState({ utm: responseJson.utm });
                this.setState({ imacec: responseJson.imacec });
                this.setState({ tpm: responseJson.tpm });
                this.setState({ libra_cobre: responseJson.libra_cobre });
                this.setState({ tasa_desempleo: responseJson.tasa_desempleo });
                this.setState({ bitcoin: responseJson.bitcoin });
            });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <CartaValores moneda={this.state.uf} onclick="/detallemoneda/uf" />
                    <CartaValores moneda={this.state.ivp} onclick="/detallemoneda/ivp" />
                    <CartaValores moneda={this.state.dolar} onclick="/detallemoneda/dolar" />
                    <CartaValores moneda={this.state.dolar_intercambio} onclick="/detallemoneda/dolar_intercambio" />
                    <CartaValores moneda={this.state.euro} onclick="/detallemoneda/euro" />
                    <CartaValores moneda={this.state.ipc} onclick="/detallemoneda/ipc" />
                    <CartaValores moneda={this.state.utm} onclick="/detallemoneda/utm" />
                    <CartaValores moneda={this.state.imacec} onclick="/detallemoneda/imacec" />
                    <CartaValores moneda={this.state.tpm} onclick="/detallemoneda/tpm" />
                    <CartaValores moneda={this.state.libra_cobre} onclick="/detallemoneda/libra_cobre" />
                    <CartaValores moneda={this.state.tasa_desempleo} onclick="/detallemoneda/tasa_desempleo" />
                    <CartaValores moneda={this.state.bitcoin} onclick="/detallemoneda/bitcoin" />
                </div>
            </div>
        );
    }
}

export default Home;

