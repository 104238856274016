import React from 'react';

function Contacto() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card mt-4">
                        <div className="card-header">
                            <h3>Contacto</h3>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="form-group">
                                    <label for="nombre">Nombre</label>
                                    <input type="text" class="form-control" id="nombre" aria-describedby="descripcionNombre" placeholder="Ingrese su Nombre" />
                                    <small id="descripcionNombre" class="form-text text-muted">Su nombre será utilizado para responder su requerimiento.</small>
                                </div>
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="email" class="form-control" id="email" aria-describedby="descripcionEmail" placeholder="Ingrese su Email" />
                                    <small id="descripcionEmail" class="form-text text-muted">Su email solo se utilizara para fines de contacto.</small>
                                </div>
                                <div class="form-group">
                                    <label for="descripcion">Detalle</label>
                                    <textarea class="form-control" id="descripcion" rows="5" aria-describedby="descripcionDetalle"></textarea>
                                    <small id="descripcionDetalle" class="form-text text-muted">Ingrese su petición o requerimiento.</small>
                                </div>
                                <hr />
                                <button type="submit" class="btn btn-success btn-block">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacto;