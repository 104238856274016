// Requerido
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';

// Componentes
import Navegacion from './components/Navegacion';

// Vistas 
import Home from './views/Home';
import DetalleMoneda from './views/DetalleMoneda';
import Contacto from './views/Contacto';
import AcercaDe from './views/AcercaDe';
import Privacidad from './views/Privacidad';

class App extends Component {
  constructor() {
    super();
    this.state = {
      titulo: 'Valor Divisas'
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App mb-4" >
          <Navegacion titulo={this.state.titulo} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/detallemoneda/:moneda" component={DetalleMoneda} />
            <Route path="/contacto" component={Contacto} />
            <Route path="/acercade" component={AcercaDe} />
            <Route path="/privacidad" component={Privacidad} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;