import React from 'react';

function CartaValores(props) {
    if (props.onclick != null) {
        /*
        return (
            <div className="col-md-3 text-center mt-4" >
                <div className="card h-100">
                    <div className="card-header">
                        <h4>{props.moneda.nombre}</h4>
                    </div>
                    <div className="card-body">
                        <h4 className="font-weight-bold">{props.moneda.valor}</h4>
                        <p>Unidad Medida: <label className="font-weight-bold">{props.moneda.unidad_medida}</label>
                            <br />
                            Fecha: <label className="font-weight-bold">{new Date(props.moneda.fecha).toLocaleDateString()}</label>
                        </p>
                    </div>
                    <div className="card-footer">
                        <a className="btn btn-success btn-block btn-sm" href={props.onclick}>Mas Detalle</a>
                    </div>
                </div>
            </div >
        );
        */

       return (
        <div className="col-md-3 text-center mt-4" >
            <div className="card h-100">
                <div className="card-header">
                    <h4>{props.moneda.nombre}</h4>
                </div>
                <div className="card-body">
                    <h4 className="font-weight-bold">{props.moneda.valor}</h4>
                    <p>Unidad Medida: <label className="font-weight-bold">{props.moneda.unidad_medida}</label>
                        <br />
                        Fecha: <label className="font-weight-bold">{new Date(props.moneda.fecha).toLocaleDateString()}</label>
                    </p>
                </div>
            </div>
        </div >
    );
    } else {
        return (
            <div className="col-md-3 text-center mt-4" >
                <div className="card h-100">
                    <div className="card-header">
                        <h4 className="font-weight-bold" title={props.moneda.valor}>{Math.round((props.moneda.valor + Number.EPSILON) * 100) / 100}</h4>
                    </div>
                    <div className="card-body">
                        <p>Unidad Medida: <label className="font-weight-bold">{props.moneda.unidad_medida}</label>
                            <br />
                            Fecha: <label className="font-weight-bold">{new Date(props.moneda.fecha).toLocaleDateString()}</label>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default CartaValores;